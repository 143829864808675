import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "gatsby-image"
import SEO from "../components/seo"

const IntroductionPage = ({ data }) => (
  <Layout>
    <SEO title="Map" />
    <Image fluid={data.illustration.childImageSharp.fluid} />
    <div class="content-text-with-header">
      <p>In the beginning&#x2026;.who really knows? There are many intriguing theories, but those who know aren&#x2019;t telling, and those who tell probably don&#x2019;t know. Some of the latter are even honest enough to admit that they&#x2019;re guessing.</p>
      <p>Among the learned of the world of Th&#x2019;eia, the elven Arch-mage Fisalvis is generally credited with the most compelling theory:</p>
      <blockquote>
        <p>
          <em>&#x201D;From the nullity of Chaos, our Universe erupted convulsively.</em>
        </p>
        <p>
          <em>Time, energy and matter were born in an instant, and with them magic &#x2013; the force that can be used to manipulate the other elements of the Universe.</em>
        </p>
        <p>
          <em>But even as Order asserted itself, Chaos sowed the seeds for its return: the new Order of the Universe split into multiple planes, like a fractured crystal rather than the pure, singular gem Order had sought. Entire portions of the Universe were replicated many times over, but on different planes of existence.</em>
        </p>
        <p>
          <em>Further, magic was widely but unevenly dispersed. Some planes, some worlds, were saturated with magic, others were endowed with next to none.</em>
        </p>
        <p>
          <em>Thus multiple worlds adjoin or relate to one another, and those who know how can observe or travel at will &#x2013; if the conditions are right - between them. &#x201C;</em>
        </p>
      </blockquote>
      <p>Gaia is our world, but in another plane close by is her twin sister Th&#x2019;eia. Separated at birth, they took very different paths - for Th&#x2019;eia is rich in magic and linked by it to many other worlds and planes.</p>
      <p>However, the devastating Mage Wars - a series of great wars that raged between jealous and ambitious mages and their non-magical followers across more than a millennium, and the chaotic aftermath that followed - resulted in most major portals to other worlds being lost, or sealed &#x2013; from the other side to Th&#x2019;eia</p>
      <p>Lesser portals can still be found or created on Th&#x2019;eia by suitably skilled wizards &#x2013; bottomless bags, enchanted pavilions and portal spells are examples. Even the high magic of summoning can call in the natives of other worlds on occasion. However, by and large, the visitors to Th&#x2019;eia from other worlds now are generally those who choose to visit Th&#x2019;eia for their own purposes, ranging from holidaying to specialised predation.</p>
      <p>Th&#x2019;eia is near to but not of the group of worlds where magic runs amok, the best known of which is Faerie, largely because the folk of that realm so like to visit other worlds, including Th&#x2019;eia. Paradoxically, Th&#x2019;eia is also near to but not of the plane of a group of worlds where magic is so weak it is largely relegated to fables for the credulous &#x2013; one such world is our own.</p>
      <p>Magic is nevertheless so powerful on Th&#x2019;eia that it manifests in many forms, and has been well studied there because discipline is needed to use it. &#xA0;Consequently some beings
        <em>are</em>&#xA0;magical, others
        <em>have</em>&#xA0;magic as part of their being and others can
        <em>use</em>&#xA0;magic Th&#x2019;eia&#x2019;s mages are among the most flexible and powerful of all the worlds, enjoying both rich sources of magic denied to worlds like Gaia, but having to exercise skill to use it.</p>
      <p>On the other hand, magic is not so pervasive on Th&#x2019;eia that
        <em>all</em>&#xA0;beings are, have or can use it &#x2013; unlike, for example, Faerie. Technology has its place on Th&#x2019;eia as well, but is less important than on those worlds where magic isn&#x2019;t available to do life&#x2019;s heavy lifting.</p>
      <p>Beings? Th&#x2018;eia has numerous intelligent and semi-intelligent kindred. Humans are just one of many. Many are known in the folklore of Gaia, for dreams may cross between the worlds to receptive minds, but their reputed natures may not always be as those portrayed here.</p>
      <p>Most of the kindred can interbreed. This has led to extensive cultural customs, attitudes, mores, strictures and taboos, despite which accidents happen, or that strange, unfathomable and beautiful form of magic that two beings can create between themselves called love may be involved&#x2026;.</p>
      <p>Further complications occur from the vast differences of aging between many of the kindred, and the rates at which they reproduce. Humans and orcs, for example, generally have only the traditional three score years and ten but are notoriously fecund. Most, but not all, elves may live for millennia, but elves rarely have more than three or four children across those many centuries.</p>
      <p>Most of the stories here are in a time several centuries after the Mage Wars and the following Troubled Times, when the emergence of the Guilds and Protectorates is finally returning the world to a semblance of order. That time is roughly equivalent to our own in this world.</p>
    </div>
  </Layout>
)

export default IntroductionPage

export const query = graphql`
  query {
    illustration: file(relativePath: { eq: "page_introduction.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

